<template>
  <div>
    <AppHeader />
    <slot />
    <AppFooter />
  </div>
</template>

<style scoped lang="scss">
div {
  overflow-x: hidden;
}
</style>

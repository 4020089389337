<script setup lang="ts">
const baseURL = useRuntimeConfig().app.baseURL;
const logo = `${baseURL}img/CMLA.png`;
</script>

<template>
  <footer>
    <div class="container">
      <div class="row">
        <div class="col-sm-5">
          <img :src="logo" />
        </div>
        <div class="col-sm-7">
          Tel: (852) 3963 5281<br />
          Email: cmla@hsu.edu.hk<br />
        </div>
      </div>
      <div class="copy">© 2024 The Hang Seng University of Hong Kong</div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';

footer {
  font-size: small;
  padding: 20px;

  .text {
    display: flex;
    align-items: center;
  }

  img {
    max-width: 100%;
    max-height: 60px;
    margin-right: 50px;
  }

  .copy {
    margin-top: 0.5em;
  }
}
</style>

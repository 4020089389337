export const useNavMenuStore = defineStore('navMenu', () => {
  const isOpen = useState('menu.isOpen', () => false);
  const items = computed(
    // region fixtures
    () =>
      [
        {
          text: 'About CMLA',
          to: '/',
        },
        {
          text: 'Our Mission',
          to: '/#our-mission',
          noHighlight: true,
        },
        {
          text: 'Scope of Services ',
          to: '/#scope-of-services',
          noHighlight: true,
        },
        {
          text: 'CMLA Conference',
          to: '/conference',
        },
        {
          text: 'News',
          to: '/news',
        },
        {
          text: 'Committee',
          to: '/#committee',
          noHighlight: true,
        },
        {
          text: 'Links',
          to: '/#links',
          noHighlight: true,
        },
      ] as MenuItem[],
    // endregion
  );

  const toggle = (x?: boolean) => {
    isOpen.value = x ?? !isOpen.value;
  };

  return {
    isOpen,
    items,
    toggle,
  };
});

export interface MenuItem {
  text?: string;
  to?: string;
  href?: string;
  noHighlight?: boolean;
}

<script setup lang="ts">
defineProps({
  siteName: {
    type: String,
    default: 'iCAPE',
  },
});

const menu = useNavMenuStore();

const closeMenu = () => {
  menu.toggle(false);
};
watch(
  () => menu.isOpen,
  (val) => {
    requestAnimationFrame(() => {
      if (val) {
        addEventListener('click', closeMenu);
      } else {
        removeEventListener('click', closeMenu);
      }
    });
  },
  { flush: 'post' },
);
onUnmounted(() => {
  removeEventListener('click', closeMenu);
});
const baseURL = useRuntimeConfig().app.baseURL;
const siteLogo = `${baseURL}img/CMLA.png`;
</script>

<template>
  <header>
    <div class="menu-bar" :class="{ showMenu: menu.isOpen }">
      <div class="flex-grow-1 header-title">
        <NuxtLink to="/">
          <img class="site-name" :src="siteLogo" alt="CMLA Logo" />
        </NuxtLink>
      </div>
      <button class="menu-btn" @click="menu.toggle()">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 21">
          <path
            d="M2 10.5H25"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 2H25"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 19H25"
            stroke="currentColor"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <ul class="nav">
        <li
          class="nav-item"
          v-for="(item, i) in menu.items"
          :key="i"
          @click="closeMenu"
        >
          <NuxtLink
            v-if="item.to"
            :to="item.to"
            :class="{ 'no-highlight': item.noHighlight }"
          >
            {{ item.text }}
          </NuxtLink>
          <a v-else :href="item.href">
            {{ item.text }}
          </a>
        </li>
      </ul>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';

header {
  padding: 10px;
  background: #fff;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}

.site-name {
  object-fit: contain;
  max-height: 60px;
  max-width: 100%;
}

.menu-bar {
  min-height: 90px;
  display: flex;
  align-items: center;
}

.header-title {
  margin-left: 24px;

  @include media-breakpoint-down(sm) {
    margin-left: 0;
  }
}

.site-name {
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
}

.nav {
  list-style: none;
  margin: 0;
  padding: 0;

  .router-link-active:not(.no-highlight) {
    color: $primary;
  }

  @include media-breakpoint-down(xl) {
    display: block;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: $body-bg;
    box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-out;

    .showMenu & {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.nav-item {
  margin: 0 24px;
  cursor: pointer;

  @include media-breakpoint-down(xl) {
    > * {
      min-height: 3rem;
      display: block;
    }
  }
}

.menu-btn {
  display: none;
  border: none;
  background: transparent;
  margin-left: 24px;
  padding: 8px;

  svg {
    width: 32px;
    height: 32px;
  }

  @include media-breakpoint-down(xl) {
    display: block;
  }
}
</style>
